import { environment } from '../../environments/environment';

export const BASE_URL = environment.apiUrl;
export const AWARD_URL = environment.AwardForm;
export const VALID_Email = environment.validEmail;
export const VALID_Ph = environment.validPh;
export const VALID_Id = environment.validId;
export const VALID_Pass = environment.validPasp;

export const NATIONALITY_SAUDI = '4d6c60ef-3ac2-127e-206f-aa7f2c70a260';
export const DATE_FMT = 'MMM d, y';
export const DATE_TIME_FMT = `${DATE_FMT} h:mm a`;

export enum AccountType {
  None = 0,
  AramcoEmployee = 1,
  AramcoDependent = 2
}

// export enum Nationality {
//   Saudi = 0,
//   Other = 1
// }

export const ParticipationHours = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 }];


export enum RegexpPattern {
  youTubeLinkValidators = '^(http(s)??\\:\\/\\/)?(www\\.)?((youtube\\.com\\/watch\\?v=)|(youtu.be\\/))([a-zA-Z0-9\\-_])+$',
  password = '^(?=.*[\\d])(?=.*[a-z])(?=.*[A-Z])(?!.*\\<)(?!.*\\>)(?!.*\\s)(?=.*[\'/\\\\\\\\!"#$%&\'()*+,.\\-/:;=?@[\\]^_`{|}~]).*$',
  email = '^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+((?!\.con)(?:\.[a-zA-Z0-9-]+))*$',
  // email = '^[\\w!#$%&\'*+\\-/=?\\^_`{|}~]+(\\.[\\w!#$%&\'*+\\-/=?\\^_`{|}~]+)*@((([\\-\\w]+\\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))$',
  // phone = '^[+\\n0-9\\s\\(\\)-/\\\\\\.]*$',
  phone = '^\\++(965|966|968|973|971)+[0-9]{9}$',
  // link = '^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$',
  link = '^[^:\\/]*:\\/\\/((?=[\\da-zA-Z])[\\da-zA-Z\\-]*[\\da-zA-Z]\\.)+((xn--[\\da-zA-Z\\-]+)|([a-zA-Z]{2,}))\\/?$',
  https = '^https:\\/\\/(.*)',
  digits = '^[0-9]*$',
  letters = '^[a-zA-Z\u0621-\u064A ]*$',
  englishLetters = '^[a-zA-Z ]*$',
  arabicLetters = '^[\u0621-\u064A ]*$',
  lettersDigits = '^[0-9a-zA-Z\u0621-\u064A]*$',
  enumeration = '^[a-zA-Z;, \u0621-\u064A]*$',
  smsRecoveryCode = '^[0-9]+$'
}

export enum Section {
  Profile = 'profile',
  ProfilePersonal = 'profile/personal',
  ProfileSkills = 'profile/skills',
  ProfileAccount = 'profile/account'
}

export enum ErrorCodes {
  notExistingPhone = 14,
  notExistingEmail = 15,
  invalidPassword = 16
}

export enum TaskStatus {
  ToDo = 0,
  Doing = 1,
  Done = 2
}

export enum TaskPriority {
  Low = 0,
  Medium = 1,
  High = 2
}

export const LANGUAGES = {
  arabic: 'ar',
  english: 'en'
};

export enum ViewResultTypes {
  training = 0,
  medical = 1
}
export const transparentSource = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export const EditorConfig = {
  contentsLangDirection: 'ltr',
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
    { name: 'forms', groups: ['forms'] },
    // '/',
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'align', 'bidi', 'paragraph'] },
    { name: 'links'},
    '/',
    { name: 'image', groups: ['insert']},
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] }
  ],
  removeButtons: `Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,SelectAll,
  Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,HiddenField,CopyFormatting,Blockquote,
  BidiLtr,BidiRtl,Language,Anchor,Iframe,PageBreak,SpecialChar,Smiley,HorizontalRule,Table,Flash,Save,NewPage,
  JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Outdent,Indent,TextColor,BGColor,Maximize,About,ShowBlocks,
  Subscript,Superscript,RemoveFormat,Replace,Source`
};

