
export const environment = {
  production: false,
  hmr: false,
  // apiUrl:   'http://devvmsdefault.kacwc.org/',
  validEmail: '/CheckEmailUniqueness',
  validPh : '/CheckPhoneUniqueness',
  validId :'/CheckNationalIdUniqueness',
  validPasp : '/CheckPassportUniqueness',
  apiUrl:  '/',
  AwardForm: 'https://www.ithra.com/'
};


