<div *ngIf="!isCurrentLanguageArabic">
  <div class="row">
    <div class="col s12 l4">
      <h2>Ithra Volunteer Challenge</h2>
    </div>
    <div class="col s12 l8">
      <p>
      Ithra change makers challenge is a contest where Ithra raises the volunteers spirt by encouraging them to compete in solving various volunteering challenges in three different tracks:
      </p>
      <p>
      All groups progress and work will be evaluated by local and international committees from different foundations. There will be one winner from each track.
      </p>

      <div class="row" style="margin-bottom:60px;margin-top:55px ">
        <div class="awardicon">
          <img src="/assets/images/Community.svg" alt="Community" />
          <b>SUSTAINABILITY</b><br />
          Engage volunteers in sustainability initiatives that positively impact the environment.
          <br />
        </div>

        <div class="awardicon">
          <img src="/assets/images/Culture.svg" alt="Community" />
          <b>EMPOWERMENT</b> <br />
          Empower volunteers to develop new skills that enhance their personal growth, efficiency, and ability to make a meaningful impact
          <br />

        </div>

        <div class="awardicon">
          <img src="/assets/images/education.svg" alt="Community" />
          <b>PROMOTION</b> <br />Engage the volunteers in documenting and showcasing Ithra change makers Challenge and highlighting the experience's impact.
        </div>
      </div>
    </div>

  </div>

  <div class="row" style="margin-bottom:100px">

    <div class="col s12 l4">
      <p> <img src="../../../assets/images/IthraAward.jpg" style="border-radius:20px" alt="" /> </p>
    </div>
    <div class="col s12 l8" style="line-height:1;">
    <br/>
    <br/><br/><br/>
      <p>
      Are you an Ithra volunteer with big ambitions?
      </p>
      <p>Then you can be the winner of the Ithra Change Makers challenge... Do not miss the chance to show your talents!</p>
      <br/>
      <p>Please read the terms and conditions carefully before completing your registration.</p>
    </div>
  </div>


  <div class="row Terms" style="border-radius: 20px;margin-bottom:100px">
    <div class="col s12 l4">
      <h2>Terms and Conditions</h2>
    </div>
    <div class="col s12 l8" style="margin-top:22px; margin-bottom:22px">
      <p> 1. Group submission only (Each group must have 5 members only).<br />
        2. Any nationality may participate.<br />
        3. The participant must not have any violations on record. <br />
        4. Participants must be at least 16 years of age. <br /> 
        5. The volunteer must complete a minimum 100 hours with Ithra. <br />
        </p>
    </div>
  </div>


  <div class="row timeline" style="margin-top:50px">
    <div style=" margin-top:45px; margin-bottom:40px">
      <h4>Ithra Volunteer Challenge Timeline</h4>
    </div>
    <div [ngStyle]="{'background':'rgba(87, 98, 104, 0.03)','margin-left':'12px','text-align':'center'}">
      <p style="margin-top: 70px;"><img src="/assets/images/awards-timeline-en1.png" alt="Timeline" /> </p>
    </div>

  </div>
  <div class="row">
    <!-- <div class="col s12" style="margin-top:35px">
      <h4>Frequently asked questions
        <a href="https://www.ithra.com/en/faqs-ithra-volunteer-award/" target="_blank">Click here</a>
      </h4>
    </div> -->

    <!-- <div class="row" [ngStyle]="{'text-align':'center'}">
      <button class="IE-padding" mvmsDebounceClick (debounceClick)="applyAward()"
        [debounceTime]="2000">{{'SHARED.Button.award' | translate }}</button>
    </div> -->

  </div>
</div>
<div *ngIf="isCurrentLanguageArabic">
  <div class="row">
    <div class="col s12 l4">
      <h2>تحدي إثراء للتطوع</h2>
    </div>
    <div class="col s12 l8">
      <p>
      تحدي صنّاع التغيير في إثراء هي مسابقة تشجع المتطوعين على التنافس لحل تحديات تطوعية متنوعة ضمن ثلاثة مسارات مختلفة:
      </p>
      <p>
      كما سيتم تقييم حلول وأعمال جميع الفرق من قبل لجان محلية ودولية من مؤسسات مختلفة. وسيكون هناك فائز واحد من كل مسار.
      </p>

      <div class="row" style="margin-bottom:60px;margin-top:55px">
        <div class="awardicon" [ngStyle]="{'float':'right', 'text-align':'center'}">
          <img src="/assets/images/Community.svg" alt="Community" />
          <b>التعزيز</b> إشراك المتطوعين في توثيق وإبراز تحدي صنّاع التغيير وتسليط الضوء على تأثير التجربة.
        </div>

        <div class="awardicon" [ngStyle]="{'float':'right', 'text-align':'center'}">
          <img src="/assets/images/Culture.svg" alt="Community" />
          <b>التمكين</b> تمكين المتطوعين من تطوير مهارات جديدة تعزز نموهم الشخصي وكفاءتهم وقدرتهم على تحقيق تأثير ملموس.
        </div>

        <div class="awardicon" [ngStyle]="{'float':'right', 'text-align':'center'}">
          <img src="/assets/images/education.svg" alt="Community" />
          <div class="awardcontainer">
            <b>الاستدامة</b> إشراك المتطوعين في مبادرات تعزز الاستدامة وأثرها الإيجابي على البيئة.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="margin-bottom:100px">
    <div class="col s12 l4">
      <p> <img src="../../../assets/images/IthraAward.jpg" style="border-radius:20px" alt="" /> </p>
    </div>
    <div class="col s12 l8" style="line-height:1; margin-top:22px">
    <br/><br/>
      <br/>
      <p>هل أنت متطوع في إثراء ولديك طموحات كبيرة؟</p>
      <p>انتهز الفرصة، وأبرز مواهبك فربما تكون الفائز بجائزة صنّاع التغيير!</p>
      <br/>
      <p>يرجى قراءة الشروط والأحكام بعناية قبل إكمال التسجيل.</p>
    </div>
  </div>

  <div class="row Terms" style="border-radius: 20px;margin-bottom:100px">
    <div class="col s12 l4" style="Margin-top:22px">
      <h2>الأحكام و<br />الشروط</h2>
    </div>
    <div class="col s12 l8" style="margin-top:22px; margin-bottom:21px">
      <p> 1. المشاركة للمجموعات فقط (يجب أن تتكون كل مجموعة من 5 أعضاء فقط).<br />
        2. يمكن لأي جنسية المشاركة.<br />
        3. يجب أن لا يكون للمشارك أي مخالفات مسجلة.<br />
        4. يجب أن يكون عمر المشاركين 16 سنة على الأقل.<br />
        5. على المتطوع أن يُكمل 100 ساعة تطوعية في إثراء كحد أدنى <br />
       </p>

    </div>
  </div>

  <div class="row timeline" style="margin-top:50px">
    <div style="margin-top:45px; margin-bottom:40px">
      <h4> الخط الزمني لتحدي إثراء للتطوع</h4>
    </div>
    <div style="margin-bottom:60px ;" [ngStyle]="{'background':'rgba(87, 98, 104, 0.03)','margin-left':'12px','text-align':'center'}">
      <p style="margin-bottom: 25px;"> <img src="/assets/images/awards-timeline-ar1.png" alt="Timeline" /> </p>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col s12" style="margin-top:35px">
      <h4>الأسئلة الشائعة لجائزة إثراء للتطوع
        <a href="https://www.ithra.com/ar/faqs-ithra-volunteer-award/" target="_blank"> انقر هنا </a>
      </h4>
    </div>
  </div> -->

<!-- 
  <div class="row" [ngStyle]="{'text-align':'center'}">
    <button class="IE-padding" mvmsDebounceClick (debounceClick)="applyAward()"
      [debounceTime]="2000">{{'SHARED.Button.award' | translate }}</button>
  </div> -->
</div>