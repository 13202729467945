import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '../account.service';
import { RegexpPattern, ErrorCodes } from '../../../constants/constant';
import { CreateUserService } from '../../../services/create-user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { LanguageService } from '../../../services/language.service';

const STATES = {
  initial: 'initial',
  bySms: 'bySms',
  byEmail: 'byEmail',
  info: 'info',
  reset: 'reset'
};

const RESET_BY_TYPES = [
  {
    type: 'sms',
    value: 'LOGIN.sms'
  },
  {
    type: 'email',
    value: 'LOGIN.Email'
  },
];

@Component({
  selector: 'mvms-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
  currentState = STATES.initial;

  states = STATES;

  resetByTypes = RESET_BY_TYPES;

  timer = 180;

  captchaKey: string;

  isLoading: boolean;

  emailChecked = false;
  phoneChecked = false;

  blockTitle = 'Forgot your password';

  nextStateForm = this.fb.group({
    currentRecoveryType: ['', [Validators.required]]
  });

  recoverPassByEmailForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.email))]],
    recaptchaResponse: [null, [Validators.required]]
  });

  recoverPassBySmsForm = this.fb.group({
    phoneNumber: ['', [Validators.required, Validators.minLength(8), Validators.pattern(new RegExp(RegexpPattern.phone))]],
    recaptchaResponse: [null, [Validators.required]]
  }, );

  codeForm = this.fb.group({
    codeNumber: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(new RegExp(RegexpPattern.smsRecoveryCode))]],
  });
  public isCurrentLanguageArabic: boolean;

  private intervalId = 0;

  private seconds = 180;

  private currentPhoneNumber: string;

  constructor(public fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private formBuilder: FormBuilder,
    private createUserService: CreateUserService,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private languageService: LanguageService) {
  }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;

    this.captchaKey = window.location.host.startsWith('localhost')
      ? '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1' : '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1';

    this.currentState = this.states.initial;
    this.recoverPassByEmailForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.email))]],
      recaptchaResponse: [null, [Validators.required]]
    });
    
  }

  closeDialog() {
    this.dialogRef.close();
  }

  nextState() {
    if (this.nextStateForm.dirty && this.nextStateForm.valid) {
      this.currentState = this.nextStateForm.value.currentRecoveryType === this.resetByTypes[0].type ?
        this.states.bySms : this.states.byEmail;
    }
  }


  recoverPassByEmail() {
    if (this.recoverPassByEmailForm.dirty && this.recoverPassByEmailForm.valid) {
      this.isLoading = true;

      this.accountService.recoverPasswordByEmail(this.recoverPassByEmailForm.value)
        .subscribe(
          () => {
            this.isLoading = false;
            this.currentState = this.states.info;
            this.blockTitle = 'Please check your email';
            setTimeout(() => {
              this.dialogRef.close();
            }, 3000);
          },
          error => {
            // this.isLoading = false;
            // this.recoverPassByEmailForm.controls.recaptchaResponse.reset();
            // if (error.error.code === ErrorCodes.notExistingPhone) {
            //   this.recoverPassByEmailForm.controls.phoneNumber.setErrors({ 'notExist': true });
            // }
            
            this.isLoading = false;
            this.currentState = this.states.info;
            this.blockTitle = 'Please check your email';
            setTimeout(() => {
              this.dialogRef.close();
            }, 3000);
          });
    }
  }


  resendCode() {
    if (this.timer === 0) {
      this.currentState = this.states.bySms;
      this.seconds = 180;
    }
  }

  recoverPassBySms() {
    if (this.recoverPassBySmsForm.dirty && this.recoverPassBySmsForm.valid) {
      this.isLoading = true;

      const number = this.recoverPassBySmsForm.value.phoneNumber;

      this.accountService.recoverPasswordBySms(this.recoverPassBySmsForm.value)
        .subscribe(
          () => {
            this.currentPhoneNumber = number;
            this.currentState = this.states.reset;
            this.countDown();
            this.isLoading = false;
          },
          error => {
            // this.isLoading = false;
            // if (error.error.code === ErrorCodes.notExistingEmail) {
            //   this.recoverPassBySmsForm.controls.email.setErrors({ 'notExist': true });
            // }

            this.currentPhoneNumber = number;
            this.currentState = this.states.reset;
            this.countDown();
            this.isLoading = false;

          });
    }
  }


  submitCode() {
    if (this.codeForm.dirty && this.codeForm.valid) {
      const model = {
        phoneNumber: this.currentPhoneNumber,
        code: this.codeForm.value.codeNumber
      };
      this.accountService.getTokenByCode(model).subscribe((resp: any) => {
        if (resp && resp.token && resp.userId) {
          this.dialogRef.close();
          this.router.navigateByUrl(`/account/change-password?userId=${resp.userId}&token=${resp.token}`);
        }
      }, error => {
        if (error.error.code === 16) {
          this.codeForm.controls.codeNumber.setErrors({ 'incorrectCode': true });
        }
      });
    }
  }

  private clearTimer() {
    window.clearInterval(this.intervalId);
    this.seconds = 180;
  }

  private countDown() {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      this.timer = this.seconds;
      if (this.seconds === 0) {
        this.dialogRef.close();
        this.timer = 0;
        this.clearTimer();
      }
    }, 1000);
  }

  checkRecoveryPhone() {
    if (this.recoverPassBySmsForm.controls.phoneNumber.valid) {
      this.createUserService.checkPhone({ phoneNo: this.recoverPassBySmsForm.controls.phoneNumber.value }).subscribe((res: any) => {
        this.phoneChecked = false;
        if (res.isUnique) {
          this.recoverPassBySmsForm.controls.phoneNumber.setErrors({ 'notExist': true });
        } else if (!res.isValid) {
          this.recoverPassBySmsForm.controls.phoneNumber.setErrors({ 'notValid': true });
        } else if (!res.isUnique && res.isUnavailable) {
          this.recoverPassBySmsForm.controls.phoneNumber.setErrors({ 'notExist': true });
        } else {
          this.recoverPassBySmsForm.updateValueAndValidity();
          this.phoneChecked = true;
        }
      });
    }
  }

  checkRegistrationEmail() {
    if (this.recoverPassByEmailForm.controls.email.dirty && this.recoverPassByEmailForm.controls.email.valid) {
      this.createUserService.checkEmail({ email: this.recoverPassByEmailForm.controls.email.value }).subscribe((res: any) => {
        this.emailChecked = false;
        if (res.isUnique) {
          this.recoverPassByEmailForm.controls.email.setErrors({ 'notExist': true });
        } else if (res.isUnavailable) {
          this.recoverPassByEmailForm.controls.email.setErrors({ 'notExist': true });
        } else {
          this.recoverPassBySmsForm.updateValueAndValidity();
          this.emailChecked = true;
        }
      });
    }
  }
}
