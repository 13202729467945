<div [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div class="btn-close-dialog">
    <button mat-icon-button (click)="closeDialog()" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div id="forgot-password">
    <div id="forgot-password-form-wrapper">
      <div id="forgot-password-form">
        <div class="title">{{ 'LOGIN.RECOVER' | translate }}</div>
        <div class="form-box" [ngSwitch]="currentState">
          <div class="code-request" *ngSwitchCase="states.initial">
            <form class='form' [formGroup]="nextStateForm" (ngSubmit)="nextState()">
              <mat-radio-group class="radio-group" formControlName="currentRecoveryType">
                <mat-radio-button class="radio-button" color="primary" *ngFor="let type of resetByTypes" [value]="type.type">{{ type.value | translate }}</mat-radio-button>
              </mat-radio-group>
              <div class="btn-submit">
                <button type="submit" mat-raised-button class="submit-button" color="accent" >{{ 'LOGIN.next' | translate }}</button>
              </div>
            </form>
          </div>
          <div class="code-request" *ngSwitchCase="states.byEmail">
            <form class='form' [formGroup]="recoverPassByEmailForm" (ngSubmit)="recoverPassByEmail()">
              <mat-form-field class="full-width field-row">
                <input matInput formControlName="email"
                      type='email'
                      placeholder="{{ 'LOGIN.emailAdress' | translate }}"  >                                          
                <mat-error *ngIf="recoverPassByEmailForm.controls['email'].hasError('required')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>
                <mat-error *ngIf="recoverPassByEmailForm.controls['email'].hasError('pattern')">
                  {{'SHARED.Errors.emailInvalid' | translate }}
                </mat-error>
              </mat-form-field>
              <re-captcha class="captcha" [siteKey]="captchaKey" formControlName="recaptchaResponse"></re-captcha>
              <div class="btn-submit">
                <button [disabled]="isLoading" type="submit" mat-raised-button  class="submit-button" color="accent">{{ 'LOGIN.send' | translate }}
                </button>
              </div>
            </form>
          </div>
          <div class="code-request" *ngSwitchCase="states.bySms">
            <form class='form' [formGroup]="recoverPassBySmsForm" (ngSubmit)="recoverPassBySms()">
              <mat-form-field class="full-width" class="phone ltr">
                <input  matInput formControlName="phoneNumber" type='text' class="phone ltr" placeholder="{{ 'SHARED.Placeholders.phone' | translate }}"  
                >
                <mat-error *ngIf="recoverPassBySmsForm.controls['phoneNumber'].hasError('required')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>
                <mat-error *ngIf="recoverPassBySmsForm.controls['phoneNumber'].hasError('pattern') || recoverPassBySmsForm.controls['phoneNumber'].hasError('notValid')">
                  {{'SHARED.Errors.PhoneNotValid' | translate }}
                </mat-error>
              </mat-form-field>
              <re-captcha class="captcha" [siteKey]="captchaKey" formControlName="recaptchaResponse"></re-captcha>
              <div class="btn-submit">
                <button [disabled]="isLoading" type="submit" mat-raised-button class="submit-button" color="accent" tabindex="3">{{ 'LOGIN.send' | translate }}
                </button>
              </div>
            </form>
          </div>
          <div class="enter-code" *ngSwitchCase="states.info">
            <h3 class="action-message">{{'LOGIN.CheckEmail' | translate}}</h3>
          </div>
          <div class="enter-code" *ngSwitchCase="states.reset">
            <h3 class="action-message">{{ 'LOGIN.insertReceivedCode' | translate }}</h3>
            <form class='form form_sms' [formGroup]="codeForm" (ngSubmit)="submitCode()">
              <div>
                <mat-form-field>
                  <input matInput formControlName="codeNumber" type='text' class="number-hide-button number-padding" maxlength="6">
                  <mat-error *ngIf="codeForm.controls['codeNumber'].hasError('pattern')">
                    {{'SHARED.Errors.digitsOnly' | translate }}
                  </mat-error>
                  <mat-error *ngIf="codeForm.controls['codeNumber'].hasError('required')">
                    {{'SHARED.Errors.emptyField' | translate }}
                  </mat-error>
                  <mat-error *ngIf="codeForm.controls['codeNumber'].hasError('minlength') || codeForm.controls['codeNumber'].hasError('maxlength')">
                    {{'SHARED.Errors.incorrectCode' | translate }}
                  </mat-error>
                  <mat-error *ngIf="codeForm.controls['codeNumber'].hasError('incorrectCode')">
                    {{'SHARED.Errors.incorrectCode' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row_timer">
                <div class="timer">
                  <p class="value">{{timer}}</p>
                  <mat-icon>access_time</mat-icon>
                  <p *ngIf="timer === 0"><a [routerLink]="" (click)="resendCode()">{{'LOGIN.ResendCode' | translate }}</a></p>
                </div>
              </div>
              <div class="btn-submit">
                <button type="submit" mat-raised-button class="submit-button" color="accent">{{'SHARED.Button.Submit' | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
